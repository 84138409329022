import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import Meta from "../components/meta"

export default () => (
  <Layout>
    <Meta />
    <div
      className="w-full py-24 px-6 bg-cover bg-no-repeat bg-center relative z-10"
      style={{
        backgroundImage:
          'url("https://images.unsplash.com/photo-1549275301-c9d60945be6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80")',
      }}
    >
      <div className="container max-w-4xl mx-auto text-center">
        <h1 className="text-5xl leading-tight tracking-wide text-center text-gray-100 mb-3 font-bold">
          MERCLIMB
        </h1>
        <p
          className="text-md md:text-lg text-center text-white mx-auto"
          style={{ width: "fit-content", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          ヤナセ出身、40年の経験を持ち<br></br>
          メルセデスベンツから認定を受けた技術者が
          <br></br>お客様に寄り添ったサポートをいたします。
        </p>
        <Link
          to={"/contact/"}
          className="mt-6 inline-block bg-red-600 text-white no-underline px-4 py-3 shadow-lg rounded"
        >
          お問い合わせ
        </Link>
      </div>
    </div>
    <div className="w-full px-6 py-12 bg-white">
      <div className="container max-w-4xl mx-auto text-center mb-6">
        <h3 className="text-xl md:text-3xl leading-tight text-center max-w-md mx-auto text-gray-900">
          サービス一覧
        </h3>
      </div>
      <div className="container max-w-4xl mx-auto text-center flex flex-wrap items-start md:flex-no-wrap">
        <div className="mb-8 w-full md:w-1/3 flex flex-col items-center justify-center px-4">
          <Link
            to={"/repair/"}
            className="w-full"
            style={{ height: "fit-content" }}
          >
            <Image
              filename="repair.jpeg"
              className="w-full object-cover mb-3"
              alt="ハードリペア"
            />
          </Link>
          <h2 className="text-xl leading-tight">ハードリペア</h2>
          <p className="mt-3 mx-auto text-sm leading-normal text-left">
            メルセデスベンツ本社から認定を受けた日本有数の技術者が、状況に合わせた適切な対応を行います。
          </p>
        </div>
        <div className="mb-8 w-full md:w-1/3 flex flex-col items-center justify-center px-4">
          <Link
            to={"/maintain/"}
            className="w-full"
            style={{ height: "fit-content" }}
          >
            <Image
              filename="maintain.jpeg"
              className="w-full object-cover mb-3"
              alt="メンテナンス"
            />
          </Link>
          <h2 className="text-xl leading-tight">メンテナンス</h2>
          <p className="mt-3 mx-auto text-sm leading-normal text-left">
            40年間の実績を生かし、様々な角度から長期的な視点でサポートします。どんなことでもご相談ください。
          </p>
        </div>
        <div className="mb-8 w-full md:w-1/3 flex flex-col items-center justify-center px-4">
          <Link
            to={"/car-list/"}
            className="w-full"
            style={{ height: "fit-content" }}
          >
            <Image
              filename="sale.jpeg"
              className="w-full object-cover mb-3"
              alt="車両販売"
            />
          </Link>
          <h2 className="text-xl leading-tight">車両販売</h2>
          <p className="mt-3 mx-auto text-sm leading-normal text-left">
            お客様に寄り添った柔軟な提案を行います。希望を受け、条件に沿った車を市場から探すことも可能です。
          </p>
        </div>
      </div>
    </div>
  </Layout>
)
